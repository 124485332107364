import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export const Links = () => {

    return (
        <Container id="links">
            <Row  >
                <Col className="text-center">
                    <a href="https://mabellszoo.com/">Mabell's Zoo</a> &#8226; <a href="https://mabellsdays.com/">Mabell's Days</a> &#8226; <a href="https://studio6thave.com/">Studio6thAve</a> &#8226; <a href="https://bjlongart.com/">BjLongArt</a>
                </Col>
            </Row>
        </Container>
    )
}