import React from "react";
import { Row, Container, Col, Figure } from 'react-bootstrap';
import { ReactComponent as FutureIsBright } from "./images/TFIB.svg";

export const Projects = () => {

    return (
        <>
            <Container id="projects" className="container">
                <Row>
                    <Col md={12} sm={12}>
                        <h2>Project Highlights</h2>
                        <p className="subtitle">I have a proven track record of building and shipping high-quality products while serving in a variety of roles. Some of these products include:</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={8} sm={12}>
                        <ul>
                            <li>Mabell's children apps with Mabell's Zoo as a finalist in The International Design for Experience Awards, Children</li>
                            <li>Belmont University &amp; Fisk Jubilee Singers — enhanced CD, <em>In Bright Mansions</em>, nominated for a Grammy</li>
                            <li>An online shopping portal with over 350 retail merchants ultimately acquired by Wave Systems</li>
                            <li>Desktop application (ScreenServer) licensed by 12 NFL teams, Sports Illustrated, National Geographic, and the NHL</li>
                            <li>State Volunteer Insurance online e-learning courses</li>
                            <li>Stratus/Champion boats online custom color-picker sales tool</li>
                            <li>Swisher Sweets 50th Anniversary promotional website featuring over $50,000 in prizes</li>
                            <li>Online chat environment ultimately acquired by Pepsi Brazil</li>
                            <li>Burn Cycle CD-ROM game with Phillips Interactive, translated into seven languages</li>
                        </ul>
                    </Col>
                    <Col className="text-center">
                        <Figure className="text-center">
                            <Figure.Image
                                width={65}
                                height={78}
                                alt="Mabell's Days Moontian"
                                src={require("./images/moontianColor.png")} />
                            <Figure.Caption>
                                Mabell's Days Moontian
                            </Figure.Caption>
                        </Figure>
                    </Col>
                </Row>
            </Container>
            <Container id="projects" className="container">
                <Row>
                    <Col>
                        <h2>Leadership and Teamwork</h2>
                        <p>My success in leading teams relies on creating environments where everyone has a voice and applies their strengths toward a common goal.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={8} sm={12}>
                        <ul>
                            <li>United 13 cohorts of software developers with lifetime relationships, support, and community in a 6 month Jr. Developer Bootcamp</li>
                            <li>Coached product teams with agile methods including project planning, code reviews, daily stand-up, and retrospective</li>
                            <li>As a college department director, I collaborated with other departments to maximize course enrollment, facilitated community involvement in the curriculum, and worked with student teams participating with non-profit organizations.</li>
                            <li>As an independent contractor, I have built teams that successfully launched websites and start-up products</li>
                        </ul>
                    </Col>
                    <Col className="text-center">
                        <Figure className="text-center">
                            <FutureIsBright width="100%" height="100%" alt="Brenda &amp; The Front End Devs" className="img-border" />
                            <Figure.Caption>
                                Brenda &amp; The Front End Devs <span>(image courtesy of <a href="https://www.linkedin.com/in/jake-lashenik/">Jake Lashenick</a>)</span>
                            </Figure.Caption>
                        </Figure>
                    </Col>
                </Row>
            </Container>
            <Container id="projects" className="container">
                <Row>
                    <Col>
                        <h2>The &#8216;Why&#8217; Behind Teaching</h2>
                        <p>As a woman in tech, I understand how it feels to be in the minority. Teaching has allowed me to help under-represented groups propel a career in tech. A few of my teaching projects include:</p>
                        <ul>
                            <li><a href="https://learn.nashvillesoftwareschool.com/blog/2020/02/26/web-design-cohort-teams-up-with-tennessee-immigrant-refugee-rights-coalition">Teaming Up With The Tennessee Immigrant &amp; Refugee Rights Coalition</a></li>
                            <li><a href="https://learn.nashvillesoftwareschool.com/blog/2018/05/29/metro-nashville-open-data-application-pitches">Group Projects Using Metro Nashville's Open Data</a></li>
                            <li><a href="https://github.com/brendalong/tell-me-a-joke" title="Tell Me A Joke Github Repo">Tell Me A Joke</a></li>
                            <li><a href="https://github.com/brendalong/everything-is-awesome" title="Everything Is Awesome Github Repo">Everything Is Awesome</a></li>
                            <li><a href="https://github.com/brendalong/christmas-list" title="Christmas List Github Repo">Christmas List</a></li>
                            <li><a href="https://github.com/brendalong/LDCC" title="Little Debbie Collector Club">Little Debbie Collector Club</a></li>
                            <li><a href="https://github.com/brendalong/MegaBites" title="MegaBites">MegaBites</a></li>
                            <li><a href="https://github.com/brendalong/womensday" title="Celebrate Women's Day">Celebrate Women's Day</a></li>
                        </ul>
                    </Col>
                </Row>
            </Container >
        </>
    )
}