import React from "react";
import { Row, Container, Col } from 'react-bootstrap';
import Bjlong from './images/bjlong-photo.png';
import { BsLinkedin, BsGithub } from "react-icons/bs";

export const About = () => {

    return (
        <Container id="about">
            <Row>
                <Col md={8} sm={12}>
                    <h2>Brenda J. Long</h2>
                    <p className="subtitle">Software Developer, UX, Mentor, Creative</p>
                </Col>
                <Col className="icon">
                    <div className={`icon linkedin icon-color`}>
                        <a
                            href="https://www.linkedin.com/in/brenda-long/"
                            target="_blank"
                            rel="noreferrer"
                            title="Brenda Long on LinkedIn"
                        >
                            <BsLinkedin /> {/* Linkedin icon */}
                        </a>
                    </div>

                    <div className={`icon git icon-color`}>
                        <a
                            href="https://github.com/brendalong"
                            target="_blank"
                            rel="noreferrer"
                            title="Brenda Long on Github"
                        >
                            <BsGithub /> {/* Github icon */}
                        </a>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={5}><img src={Bjlong} className="d-block mx-auto" alt="Brenda J. Long"/></Col>
                <Col>
                    <p>
                    Hello there! I'm an artist who discovered code as a way to create interactive experiences. I've worked in a variety of tech roles on some exciting projects. Some may call me a generalist, yet that's what happens over time. I have always advocated for the user, and I champion my current developers and desire to be their biggest fan. As a technologist, I believe we need to push the boundaries of "what's possible"; to make tech accessible and have a positive impact.
                    </p>
                    <p>My colleagues describe me as</p>
                        <ul>
                            <li>supportive</li>
                            <li>creative</li>
                            <li>visionary</li>
                            <li>energetic</li>
                            <li>trustworthy</li>
                        </ul>
                        <p>...and my <b>super-power</b> is the ability to adapt my diverse knowledge to new situations and grasp the details of a challenge.</p>
                </Col>
            </Row>
        </Container>
    )
}