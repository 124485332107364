import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BsLinkedin, BsGithub } from "react-icons/bs";

export const Footer = () => {

    return (
        <Container id="footer">
        <Row>
            <Col md={8} sm={12}>
                <h2>Let's Start A Conversation</h2>
            </Col>
            <Col className="icon">
                <div className={`icon linkedin icon-color`}>
                    <a
                        href="https://www.linkedin.com/in/brenda-long/"
                        target="_blank"
                        rel="noreferrer"
                        title="Brenda Long on LinkedIn"
                    >
                        <BsLinkedin /> {/* Linkedin icon */}
                    </a>
                </div>

                <div className={`icon git icon-color`}>
                    <a
                        href="https://github.com/brendalong"
                        target="_blank"
                        rel="noreferrer"
                        title="Brenda Long on Github"
                    >
                        <BsGithub /> {/* Github icon */}
                    </a>
                </div>
            </Col>
        </Row>
        <Row>
        <Col md={10} sm={12}>
                <p>In our modern world, let's grab a coffee over zoom. You can also send me an email or LinkedIn.</p>
            </Col>
        </Row>
        </Container>

    )
}