import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

export const Learn = () => {
    return (
        <Container id="learn">
             <Row>
                <Col>
                    <h2>Curious And Always Learning</h2>
                    <p>I am a lifelong learner exploring ideas, experiences, and tech. A few things I have been learning this past year include:</p>
                    <ul>
                        <li>React Native</li>
                        <li>NextJS</li>
                        <li>TypeScript</li>
                        <li>SupaBase</li>
                        <li>Figma</li>
                        <li>Aligning a telescope in the night sky</li>
                        <li>Riding a motorcycle</li>
                    </ul>
                </Col>
            </Row>
        </Container>
    )
}
