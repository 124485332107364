import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";

export const Challenge = () => {
    const [testVals, setTestVals] = useState({
        query: "",
        phrase: ""
    });
    const [newPhrase, setNewPhrase] = useState("");

    const handleClear = () => {
        setTestVals({
            query: "",
            phrase: ""
        })
        setNewPhrase("");
    }

    // Given an input string and a query, implement a function `highlight`
    // that highlights all occurrences of the query with a bold tag:
    //
    // highlight("fig", "configure figma") => "con<b>fig</b>ure <b>fig</b>ma"
    //
    // Brute force solutions are welcome. Feel free to Google or use any helper
    // methods/libraries (eg: `text.indexOf(query)` or `text.substring(i, j)`).

    //Version 1 - does not account for edge cases, especially capital letters
    function highlight(query, text) {
        let result = []
        if (text.includes(query)) {
            const textSplit = text.split(query);
            textSplit.forEach((item, index) => {
                result.push(item);
                if (textSplit.length - 1 !== index) {
                    result.push(<b>{query}</b>)
                }
            })
            return result;
        } else {
            return [`No Results with the term:${query} and text: ${text}`]
        }
    }

    const handleGoClick = () => {
        setNewPhrase(highlightV2(testVals.query, testVals.phrase))
    }

    const handleInputChange = (event) => {
        /* create a copy, make changes, and then set state.*/
        const newTestVals = { ...testVals }
        newTestVals[event.target.id] = event.target.value;
        setTestVals(newTestVals)
    }

    const replacer = (match) => {
        //return the EXACT matched value along with b tags
        return (`<b>${match}</b>`)
    }

    function highlightV2(query, text) {
        if (testVals.query === "" || testVals.phrase === "") { return `Enter a term and phrase above` }
        //test string for query
        //string includes is case sensitive: normalize it
        if (text.toLowerCase().includes(query.toLowerCase())) {
            //create RegExp object with flags
            //g -> global search (all occurrences)
            //i -> case insensitive
            //m -> multiline
            const regEx = new RegExp(query, 'gi')
            //invoke a function with the match to maintain input case
            let newStr = text.replace(regEx, replacer)
            return newStr;
        } else {
            return `No Results with the <b>term: </b>${query} and the <b>phrase: </b>${text}`;
        }
    }

    return (
        <>
            <Container id="challenge">
                <Row>

                    <Col xs={12}>

                        <span>PS</span>
                        <p className="quote">The greatest teacher, failure is. - Yoda</p>
                        <p>A code challenge. It can start simple and provide a win. However, with edge cases, it gets more complex.</p>
                        <p className="code">Given a query and a phrase, implement a function
                            that highlights all occurrences of the query with a bold tag.</p>

                        <p>I would love to talk through the code for this solution. Questions and feedback are welcome.</p>
                        <p><small>Test Example <br />
                        <b>Query:</b> Taco<br />
                        <b>Phrase:</b> A taco is like metacode with salsa<br />
                        <b>Result:</b> A <b>taco</b> is like me<b>taco</b>de with salsa</small></p>
                    </Col>
                </Row>
                <Form>
                    <Row className="mb-3">
                        <Form.Group as={Col} xs={12} md={4}>
                            <Form.Label column="sm">Query Term</Form.Label>
                            <Form.Control id="query" onChange={handleInputChange} value={testVals.query} type="string" placeholder="Enter a term" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} md={6}>
                            <Form.Label column="sm">Phrase</Form.Label>
                            <Form.Control id="phrase" onChange={handleInputChange} value={testVals.phrase} type="string" placeholder="Enter a phrase" />
                        </Form.Group>

                        <Form.Group as={Col} className="d-flex align-items-end justify-content-end" xs={12} md={2}>
                            <Button onClick={handleGoClick} type="button">Go</Button>
                        </Form.Group>
                    </Row>
                </Form>
                <Row className="mb-3">
                    <Col xs={12} md={4}></Col>
                    <Col xs={12} md={6}>
                        <p className="col-form-label col-form-label-sm">Result</p>
                        {newPhrase !== "" ?
                            <p dangerouslySetInnerHTML={{ __html: newPhrase }} className="form-control" />
                            : <p className="form-control text-return">Checkout the result</p>}
                    </Col>
                    <Col className="d-flex align-items-center" xs={12} md={2}>
                        <Button id="clear" onClick={handleClear}>Clear</Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
