import React from "react";
import { Navbar,Container } from "react-bootstrap";
import { ReactComponent as Hellobjlong } from "./images/hellobjlong.svg";

export const NavTop = () => {

    return (
        <>
        <Navbar collapseOnSelect expand="lg" bg="iceblue" variant="light" >
            <Container fluid="sm" className="nav-max">
                <Navbar.Brand href="#home"><Hellobjlong width={100} height={100}/></Navbar.Brand>
            </Container>
        </Navbar>
     </>
    )
}