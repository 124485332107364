import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export const Tech = () => {

    return (
        <Container id="tech" className="container">
            <Row>
                <Col md={12} sm={12}>
                    <h2>Tech and Tools</h2>
                    <p>With a focus on the front end, these are some of my current tools:</p>
                    <p>
                        <img src="https://img.shields.io/badge/html5-%23E34F26.svg?style=for-the-badge&logo=html5&logoColor=white" alt="HTML5" />
                        <img src="https://img.shields.io/badge/javascript-%23323330.svg?style=for-the-badge&logo=javascript&logoColor=%23F7DF1E" alt="Javascript" />
                        <img src="https://img.shields.io/badge/bootstrap-%23563D7C.svg?style=for-the-badge&logo=bootstrap&logoColor=white" alt="Bootstrap" />
                        <img src="https://img.shields.io/badge/node.js-6DA55F?style=for-the-badge&logo=node.js&logoColor=white" alt="Node" />
                        <img src="https://img.shields.io/badge/react-%2320232a.svg?style=for-the-badge&logo=react&logoColor=%2361DAFB" alt="React" />
                        <img src="https://img.shields.io/badge/react_native-%2361dafb.svg?style=for-the-badge&logo=react&logoColor=%2320232a" alt="React Native" />
                        <img src="https://img.shields.io/badge/SASS-hotpink.svg?style=for-the-badge&logo=SASS&logoColor=white" alt="SASS" />
                        <img src="https://img.shields.io/badge/figma-%23C7B9FF.svg?style=for-the-badge&logo=figma&logoColor=white" alt="Figma" />
                        <img src="https://img.shields.io/badge/adobe-%23FF0000.svg?style=for-the-badge&logo=adobe&logoColor=black" alt="Adobe Tools" />
                        <img src="https://img.shields.io/badge/Visual%20Studio%20Code-0078d7.svg?style=for-the-badge&logo=visual-studio-code&logoColor=white" alt="Visual Studio Code" />
                        <img src="https://img.shields.io/badge/git-%23F05033.svg?style=for-the-badge&logo=git&logoColor=white" alt="Git" />
                        <img src="https://img.shields.io/badge/github-%23121011.svg?style=for-the-badge&logo=github&logoColor=white" alt="GitHub" />
                        <img src="https://img.shields.io/badge/firebase-%23039BE5.svg?style=for-the-badge&logo=firebase" alt="Firebase" />
                        <img src="https://img.shields.io/badge/Supabase-3ECF8E?style=for-the-badge&logo=supabase&logoColor=white" alt="SupaBase" />
                        <img src="https://img.shields.io/badge/Postman-FF6C37?style=for-the-badge&logo=postman&logoColor=white" alt="Postman" />
                        <img src="https://img.shields.io/badge/-JSON--Server-lightgrey?style=for-the-badge" alt="JSON-Server" />
                        <img src="https://img.shields.io/badge/DB%20Diagram-445f9d?style=for-the-badge&logoColor=white" alt="DB Diagram" />
                    </p>
                    <p><b>Additional tech tools I have used include:</b> JQuery, Browserify, Grunt, AngularJS, SQL, Python, CSharp, WordPress, AS3/ Flash, ColdFusion, Photoshop, Illustrator, and InDesign.</p>
                </Col>
            </Row>
        </Container>
    )
}