import './App.css';
import './styles/custom.scss';
import { NavTop } from './NavTop';
import { About } from './About';
import { Footer } from './Footer';
import { Projects } from './Projects';
import { Tech } from "./Tech";
import { Learn } from "./Learn";
import { Links } from "./Links";
import { Challenge } from './Challenge';

export const App = () => {
  // const accessHelloBjlong = sessionStorage.getItem()
  return (
    <div className="App">
      <NavTop />
      <About />
      <Projects />
      <Learn />
      <Tech />
      <Footer />
      <Links />
      <Challenge />
    </div>
  );
}